import React from 'react';
import {Layout, theme ,Col, Row,Image,Button} from 'antd';
import './contact.css';

function Contact(){
        return (<div className='body'><h1>Contact Us</h1>
        <h2>Clipbox</h2>
        
        <div className='lineTxt'>
        If you ran into any problems ,
        </div>
        <div className='lineTxt'>
        If you have better suggestions,

        </div>
        <div className='lineTxt'>
        if you feel our services is inadequate.
        </div>
        <div className='lineTxt'>Please contact us: 
        </div>
        <div className='lineTxt'><a href="#">service@weekshort.com</a></div>
        <div className='lineTxt'>
We will take corresponding measures and give some rewards after verification.</div>
        </div>)
}

export default Contact;