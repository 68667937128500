import React, { useState } from 'react';
import {Layout, theme ,Col, Row,Image} from 'antd';
import Home from './pages/home/home';
import Product from './pages/product/product';
import Contact from './pages/contact/contact';
import Show from './pages/show/show';
import constData from './utils/constant';
import './App.css';

const { Header, Content, Footer } = Layout;


const App: React.FC = () =>  {
  const [index,setIndex] = useState(0);


  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const app = this;

  const links = ()=>{
    const titles = ["Home","Product","Contact"];
    return (<div><Row>{titles.map(((n,_index)=>{return (<Col span={4}><div onClick={()=>{setIndex(_index)}} className={index!=_index?'link':'link-active'}>{n}</div></Col>)}))}</Row></div>);
  }

  const getPage=()=>{
    switch(index){
      case 0: return <Home />;
      case 1: return <Product />;
      //case 2:return <Show />;
      case 2: return <Contact />;
    }
    return (<Home/>)
  }


  return (<Layout>
    <Header style={{ display: 'flex', alignItems: 'center',backgroundColor:'#fff' }}>
      <Row style={{width: '100%'}}>
        <Col span={1}><Image src={require('./static/images/logo.png')} width={36} preview={false}/>
        </Col>
        <Col span={4} style={{fontSize:'35px',fontWeight:800}}>{constData.title}</Col>
        <Col span={2}></Col>
        <Col span={14}>
          {links()}
        </Col>
      </Row>
    </Header>
    <Content style={{ padding: '0 48px' }}>
      {getPage()}
    </Content>
    <Footer style={{ textAlign: 'center' }}>
      {constData.title} ©2023-{new Date().getFullYear()} {constData.url}
    </Footer>
  </Layout>
  );
}

export default App;
