import React from 'react';
import {Layout, theme ,Col, Row,Image,Button} from 'antd';
import './product.css';

function Product(){
    return (<div className='body'>
        <div style={{textAlign:'center'}}>
            <div style={{fontSize:'32px',fontWeight:"bold"}}>Introduce</div>
            Let's SINK IN.
        </div>
        <Row>
        <Col span={4}></Col>
            <Col span={8}>
                <Image src={require('../../static/images/product.png')} width={256} preview={false}/>
            </Col>
            <Col span={1}></Col>
            <Col span={6}>
                <div style={{marginTop:"32px"}}>
                    <Row>
                        <Col span={2}>
                            <Image src={require('../../static/images/kth.png')} width={"20px"}/>
                        </Col>
                        <Col span={20} style={{fontWeight:600}}>Simple Designs</Col>
                    </Row>
                    <div style={{fontSize:"14px",marginTop:"10px"}}>
                    An app that you can't keep your eyes off!
                    </div>
                </div>
                <div style={{marginTop:"32px"}}>
                    <Row>
                        <Col span={2}>
                            <Image src={require('../../static/images/mobile.png')} width={"20px"}/>
                        </Col>
                        <Col span={20} style={{fontWeight:600}}>Mobile Support</Col>
                    </Row>
                    <div style={{fontSize:"14px",marginTop:"10px"}}>
                    With our reliable developing abilities, we have our lastest verions on both iOS and Android platforms. Coming Soon!
                    </div>
                </div>
                <div style={{marginTop:"32px"}}>
                    <Row>
                        <Col span={2}>
                            <Image src={require('../../static/images/data.png')} width={"20px"}/>
                        </Col>
                        <Col span={20} style={{fontWeight:600}}>Data Security</Col>
                    </Row>
                    <div style={{fontSize:"14px",marginTop:"10px"}}>
                    Using the latest ECC(Elliptic Curve Cryptography), the data requested by users is prevented from being tampered. We will not save your data without your permission. Even if you agree, we will use advanced encryption methods to keep your data safe and we will not share your information with any third party.
                    </div>
                </div>
                <div style={{marginTop:"32px"}}>
                    <Row>
                        <Col span={2}>
                            <Image src={require('../../static/images/point.png')} width={"20px"}/>
                        </Col>
                        <Col span={20} style={{fontWeight:600}}>Rewards</Col>
                    </Row>
                    <div style={{fontSize:"14px",marginTop:"10px"}}>
                    On other apps, you can only watch movies by paying. On our app, there will be a reward system. It only takes a little time for you to get the same movie-watching experience.
                    </div>
                </div>
            </Col>
            <Col span={2}></Col>
        </Row>
    </div>);
}
export default Product;