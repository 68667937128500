import React from 'react';
import {Layout, theme ,Col, Row,Image,Button} from 'antd';
import constData from '../../utils/constant';
import './home.css';
import { relative } from 'path';

function Home(){
        return (<div >
                <Row >
                        <Col span={4}></Col>
                        <Col span={8}>
                                <div style={{marginTop:'105px',lineHeight:'105px',fontSize:"48px"}}>{constData.title}</div>
                                <div style={{fontSize:"30px",lineHeight:'36px'}}>{constData.slogan}</div>
                                <div style={{fontSize:'18px',marginTop:'42px'}}>{constData.memo}</div>
                                <Button style={{marginTop:"24px",borderRadius:"24px",height:"58px",backgroundColor:'#9c9090',color:'#fff'}}>App Coming Soon</Button>
                        </Col>
                        <Col span={8}>
                                 <Image src={require('../../static/images/app.png')} preview={false} style={{marginLeft:"32px"}}/>
                        </Col>
                        <Col span={4}></Col>
                </Row>
        </div>)
}

export default Home;